import { CheckOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Card, Col, Drawer, Form, Row, Table, Input, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect , useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Detail, DetailStates } from '../common';
import { CommonFileUpload } from '../common/CommonFileUpload';
import { useFields } from '../fields';
import { displayUtil } from '../util';
import { DocDetailDto, DocReplyDto, DocDetailSupplierDto } from './api';
import { ExpandableConfig } from 'antd/es/table/interface';
import './index.css';
import { Recoverable } from 'repl';

export interface Props {
  urlId?: string;
  detailData: DetailStates<DocDetailDto>;
  formDto?: DocDetailDto;
  supplierformDto?: DocDetailSupplierDto;
  detailOptions?: DocReplyDto[];
  visible: boolean;
  disabled: boolean;
  handleDetailRecepted: (data: DocReplyDto) => void;
  handleDetailNotRecept: (data: DocReplyDto) => void;
  handleDetailDelete: (data: DocReplyDto) => void;
  close: () => void;
  handleDownload: () => void;
}

export function DocDetail(props: Props) {
  const [form] = Form.useForm<DocDetailDto>();
  const [supplierform] = Form.useForm<DocDetailSupplierDto>();
  const acceptFlag = useFields('acceptFlag');
  const category = useFields('category');
  const suppliers = useFields('supplier');
  const fullSuppliers = useFields('fullSupplier');
  const readFlag = useFields('readFlag');
  const replyFlagOptions = useFields('replyFlag');

    const [isEdited, setIsEdited] = useState<boolean>(false);

  useEffect(() => {
    if (!!props.formDto) {
      form.setFieldsValue(props.formDto);
    }
  });

  const supplierTitle = <FormattedMessage id='docInfo.supplier' />;
  const acceptFlagTitle = <FormattedMessage id='docInfo.acceptFlag' />;
  const replyTimeTitle = <FormattedMessage id='docInfo.replyTime' />;
  const replyMessageTitle = <FormattedMessage id='docInfo.replyContent' />;
  const titleTitle = <FormattedMessage id='docInfo.title' />;
  const messageTitle = <FormattedMessage id="docInfo.sendContent" />;
  const detailTitle = <FormattedMessage id='docInfo.detail' />;
  const replyContentTitle = <>
    <FormattedMessage id='docInfo.replyInfo' />
    <Button onClick={props.handleDownload} style={{ marginLeft: 10, marginRight: 10 }} size='middle' ><FormattedMessage id='common.download' /></Button>
  </>;
  const sendContentTitle = <FormattedMessage id='docInfo.sendInfo' />;
  const replyFlagTitle = <FormattedMessage id='docInfo.replyFlag' />;
  const readFlagTitle = <FormattedMessage id='docInfo.readFlag' />;
  // 更新者
  const usernameTitle = <FormattedMessage id='docInfo.replyUserName' />;
  // 更新时间
  const modifiedTimeTitle = <FormattedMessage id='common.userModifiedDate' />;

  const replyConfirm = <FormattedMessage id='message.replyConfirm' />;
  const confirm = <FormattedMessage id='common.confirm' />;
  const replyCancel = <FormattedMessage id='common.cancel' />;
  const reply = <FormattedMessage id='docInfo.reply' />;
  const sendMessage = <FormattedMessage id='docInfo.sendContent' />;

  const col1: [JSX.Element, any][] = [
    [<FormattedMessage id='docInfo.documentCategory' />, displayUtil.field(category)(props.formDto?.documentCategory)],
    [<FormattedMessage id='docInfo.sendTime' />, displayUtil.date(props.formDto?.sendTime)],
    [<FormattedMessage id='docInfo.userName' />, props.formDto?.userName],
    // [<FormattedMessage id='common.userModifiedDate' />, displayUtil.date(props.formDto?.modifiedDate)],
    [<></>, ''],
    [<></>, ''],
    [<FormattedMessage id='common.download' />, 
      <div style={{ position: "absolute", overflowY: "auto", maxHeight: "100px", maxWidth: "250px"}} >
        <CommonFileUpload
          attachments={!!props.formDto ? props.formDto.attachments : []}
          canUpload={false}
          persist={(uuid: string) => {
            //TODO
          }}
          remove={(uuid: string) => {
            //TODO
          }}
        />
      </div>
    ],
  ]

  const col3: [JSX.Element, any][] = [
    [<FormattedMessage id='docInfo.sendQuantity' />, props.formDto?.sendQuantity],
    [<FormattedMessage id='docInfo.acceptQuantity' />, props.formDto?.readQuantity],
    [<FormattedMessage id='docInfo.replayQuantity' />, props.formDto?.replyQuantity],
  ]

  // const col2: [JSX.Element, any][] = [
  //   [<FormattedMessage id='docInfo.title' />, props.formDto?.title],
  // ]

  let deletable = false
  if(!!props.detailOptions) {
    deletable = props.detailOptions?.length <= 1
  } else {
    deletable = true
  }


  const width250 = '250px';
  const width160 = '160px';
  const width105 = '105px';
  const width102 = '100px';
  const width110 = '110px';
  const width50 = '50px';

  const column: ColumnsType<DocReplyDto> = [
    {
      title: acceptFlagTitle,
      dataIndex: 'acceptFlag',
      render: displayUtil.field(acceptFlag),
      // align: "center",
      width: width102,
      // ellipsis: true,
    },
    {
      title: readFlagTitle,
      dataIndex: "readFlag",
      width: width102,
      // ellipsis: true,
      render: displayUtil.field(readFlag)
    },
    {
      title: replyFlagTitle,
      dataIndex: 'replyFlag',
      render: displayUtil.field(replyFlagOptions),
      width: width102,
    },
    {
      title: supplierTitle,
      dataIndex: "supplier",
      render: displayUtil.field(fullSuppliers),
      // align: "center",
      width: width250,
      ellipsis: true,
    },
    {
      title: replyMessageTitle,
      dataIndex: "replyMessage",
      width: width160,
      ellipsis: true,
    },
    {
      title: usernameTitle,
      dataIndex: "userName",
      width: width110,
      ellipsis: true,
    },
    {
      title: replyTimeTitle,
      // align: "center",
      dataIndex: "replyTime",
      render: displayUtil.date,
      width: width105,
    },
    {
      width: width50,
      align: "center",
      render: v =>
        <>
          <Button disabled={deletable} size="middle" style={{ border: '1px solid rgb(217, 217, 217)' }} type="text" onClick={() => props.handleDetailDelete(v)} icon={<DeleteOutlined />} />
        </>
    },
    {
      width: width50,
      align: "center",
      render: v =>
        <>
          {v.acceptFlag !== '002'? 
            <Button size="middle" style={{ border: '1px solid rgb(217, 217, 217)' }} type="text" onClick={() => props.handleDetailRecepted(v)} icon={<CheckOutlined />} />
            :
            <Button size="middle" style={{ border: '1px solid rgb(217, 217, 217)' }} type="text" onClick={() => props.handleDetailNotRecept(v)} icon={<CloseOutlined />} />
          }
          
        </>
    }
  ]


  const expandedRowRender = (record: DocReplyDto, index: any, indent: any, expanded: any) => {
    const { TextArea } = Input;
    const fileList = <FormattedMessage id='docInfo.docFiles' />
    const textAreaVisible = record.replyMessage === null ? 'none' : 'flex'
    const fileVisible = record.detailAttachments.length === 0 ? 'none' : 'flex'

    const replyDisabled = props.disabled || ( !supplierform.getFieldValue('replyMessage') && !isEdited)

    return (
      <>
        <Row >
          <Col style={{display: textAreaVisible}} offset={1} span={14}>{replyMessageTitle}</Col>
          <Col style={{display: fileVisible, paddingTop: '7px'}} offset={1} span={8} >{fileList}</Col>
        </Row>
        
        <Row >
          <Col style={{display: textAreaVisible}} offset={1} span={14}>
            <TextArea 
              disabled
              style={{ color: 'rgba(0, 0, 0, 0.85)', cursor: 'text', padding: '0px 10px' }}
              value={record.replyMessage}
              onChange={e => e.preventDefault}
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </Col>
          <Col style={{display: fileVisible, maxHeight: '100px', overflowY: 'auto', overflowX: 'hidden'}} offset={1} span={8}>
            <div className='buyerDocDetailFileList'>
              <CommonFileUpload
                attachments={!!record ? record.detailAttachments : []}
                canUpload={false}
                persist={(uuid: string) => {
                  //TODO
                }}
                remove={(uuid: string) => {
                  //TODO
                }}
              />
            </div>
          </Col>


        </Row>
      </>
    )
  }

  const rowExpandable = (record: DocReplyDto) => { 
    return record.detailAttachments.length === 0 && record.replyMessage === null ? false : true;
  };

  const expandableProps: ExpandableConfig<DocReplyDto> = {
    defaultExpandAllRows: false,
    expandedRowRender: expandedRowRender,
    rowExpandable: rowExpandable,
    columnWidth: '40px',
  };



  return (
    <Drawer
      title={detailTitle}
      width={'100%'}
      footer={null}
      visible={props.visible}
      destroyOnClose={true}
      onClose={props.close}>
      <Form
        className="form"
        form={form}
        preserve={false}
        layout={'horizontal'}
        colon={false}
        requiredMark={true}
        size={'large'}
        style={{height: '100%'}}
      >
        <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 0, borderBottom: '1px solid #f0f0f0' }}>{sendContentTitle}</p>
        <Detail cols={col1} />

        <Card bordered={false} headStyle={{ paddingTop: 16 }} style={{ transform: 'translateY(-45px) translateX(8px)', width: '700px' }}>
          <Row gutter={[16, 18]} align="middle">
            <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
              {titleTitle}
            </Col>
            <Col span={19} style={{ textDecoration: 'underline' }}>
              {props.formDto?.title}
            </Col>
          </Row>
        </Card>

        <Card bordered={false} headStyle={{ paddingTop: 16 }} style={{ transform: 'translateY(-55px) translateX(-35px)', width: '900px' }}>
          <Row gutter={[16, 18]} align="top">
            <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
              {messageTitle}
            </Col>
            <Col span={19}>
              <Input.TextArea
                // bordered={false} 
                disabled
                style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0 10px 0 0' }}
                value={props.formDto?.message}
                onChange={e => e.preventDefault}
                autoSize={{ minRows: 3, maxRows: 3 }}
              >
              </Input.TextArea>
            </Col>
          </Row>
        </Card>
        <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 0, marginTop: 20, borderBottom: '1px solid #f0f0f0', transform: 'translateY(-60px)' }}>
          {replyContentTitle}</p>
        <Col style={{ transform: 'translateY(-60px)' }}><Detail cols={col3} /></Col>
        <Row style={{ marginTop: -10, transform: 'translateY(-60px)', height: '340px' }}>
          <Col span="24">
            <Table<DocReplyDto>
              className="list-detail-table"
              rowClassName={displayUtil.rowClassName} // テーブルは 1 行おきに色が変わります
              sticky={false}
              pagination={false}
              scroll={{ y: '38vh' }}
              columns={column}
              dataSource={props.detailOptions}
              rowKey={'id'}
              expandable={expandableProps}
            />
          </Col>
        </Row>
      </Form>
    </Drawer >
  );
}
