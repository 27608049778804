import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl, } from 'react-intl';
import { Drawer, Form, Input, Button, Row, Col, Card, Popconfirm } from 'antd';
import { DocDetailSupplierDto } from './api';
import './index.css';
import { Field, useFields } from '../fields';
import { displayUtil } from '../util';
import { CommonFileUpload } from '../common/CommonFileUpload';
import { Detail, Attachment } from '../common';
const { TextArea } = Input;

export interface Props {
  formDto?: DocDetailSupplierDto;
  visible: boolean;
  disabled: boolean;
  buyerOptions: Field[];
  handleSubmit: (data: DocDetailSupplierDto) => void;
  close: () => void;
}

export function DocReplyDetail(props: Props) {
  const [form] = Form.useForm<DocDetailSupplierDto>();
  const buyerOption = useFields('buyer');
  const documentCategoryOption = useFields('category');
  const replyFlagOptions = useFields('replyFlag');
  const acceptFlagOptions = useFields('acceptFlag');
  const replyUserName = useFields('replyUserName');
  const intl = useIntl();
  const [detailAttachments, setDetailAttachments] = useState<Attachment[]>([]);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  useEffect(() => {
    if (!!props.formDto) {
      form.setFieldsValue(props.formDto)
      setDetailAttachments(props.formDto.detailAttachments);
    }
  }, [form, props.formDto]);

  const title = <FormattedMessage tagName='h4' id='docInfo.detail' />
  const reply = <FormattedMessage id='docInfo.reply' />;
  const fileList = <FormattedMessage id='docInfo.docFiles' />;
  const sendContent = <FormattedMessage id='docInfo.sendInfo' />;
  const replyContent = <FormattedMessage id='docInfo.replyInfo' />;
  const sendMessage = <FormattedMessage id='docInfo.sendContent' />;
  const replyMessage = <FormattedMessage id='docInfo.replyContent' />;
  const confirm = <FormattedMessage id='common.confirm' />;
  const replyCancel = <FormattedMessage id='common.cancel' />;
  const replyConfirm = <FormattedMessage id='message.replyConfirm' />;


  const handleSubmit = (form: DocDetailSupplierDto) => {

    form.detailAttachments = detailAttachments;

    props.handleSubmit(form);
  }

  const col1: [JSX.Element, any][] = [
    [<FormattedMessage id='docInfo.acceptFlag' />, displayUtil.field(acceptFlagOptions)(props.formDto?.acceptFlag)], // 受け入れフラグ
    [<FormattedMessage id='docInfo.buyer' />, displayUtil.field(buyerOption)(props.formDto?.buyer)], // 発注者
    [<FormattedMessage id='docInfo.documentCategory' />, displayUtil.field(documentCategoryOption)(props.formDto?.documentCategory)], // 情報種類
    [<FormattedMessage id='docInfo.title' />, props.formDto?.title], // タイトル
    [<FormattedMessage id='docInfo.userName' />, props.formDto?.userName], // 送信担当
    [<FormattedMessage id='docInfo.sendTime' />, displayUtil.date(props.formDto?.sendTime)], // 送信時間
    [<></>, ''],
    [<></>, ''],
    [<FormattedMessage id='common.download' />, 
      <div style={{ position: "absolute", overflowY: "auto", maxHeight: "100px", maxWidth: "250px"}} >
        <CommonFileUpload
          attachments={!!props.formDto ? props.formDto.attachments : []}
          canUpload={false}
          persist={(uuid: string) => {
            //TODO
          }}
          remove={(uuid: string) => {
            //TODO
          }}
        />
      </div>
    ],
  ]

  const col2: [JSX.Element, any][] = [
    [<FormattedMessage id='docInfo.replyFlag' />, displayUtil.field(replyFlagOptions)(props.formDto?.replyFlag)], // 返信フラグ
    [<FormattedMessage id='docInfo.replyUserName' />, displayUtil.field(replyUserName)(props.formDto?.replyUserName)], // 返信担当
  ]

  const handleEditReplyMessage = (record: ChangeEvent<HTMLTextAreaElement>) => {
    if (record?.target?.value) {
      setIsEdited(true)
    } else {
      setIsEdited(false)
    }
  }

  const replyDisabled = props.disabled || ( !form.getFieldValue('replyMessage') && !isEdited)

  return (
    <Drawer
      title={title}
      width={'100%'}
      footer={null}
      visible={props.visible}
      destroyOnClose={true}
      onClose={props.close}>

      <Form
        className="form"
        form={form}
        preserve={false}
        onFinish={handleSubmit}
        layout={'horizontal'}
        colon={false}
        requiredMark={true}
        size={'large'}
        style={{height: '100%'}}
      >
        <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 0, borderBottom: '1px solid #f0f0f0' }}>{sendContent}</p>
        <Detail cols={col1} />

        <Card bordered={false} headStyle={{ paddingTop: 16 }} style={{ transform: 'translateY(-60px) translateX(50px)', width: '900px' }}>
          <Row gutter={[16, 18]} align="top">
            <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
              {sendMessage}
            </Col>
            <Col span={19}>
              <Input.TextArea
                // bordered={false} 
                disabled
                style={{ color: 'rgba(0, 0, 0, 0.85)', textDecoration: 'underline', cursor: 'text', padding: '0 10px 0 0' }}
                value={props.formDto?.message}
                onChange={e => e.preventDefault}
                autoSize={{ minRows: 3, maxRows: 3 }}
              >
              </Input.TextArea>
            </Col>
          </Row>
        </Card>

        <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 0, borderBottom: '1px solid #f0f0f0' }}>{replyContent}</p>
        <Detail cols={col2} />

        <Row justify="start">
          <Col span={12} style={{ borderLeft: '#f0f0f0 1px solid', paddingLeft: -16 }}>
              <Row gutter={[16, 0]} align="top" justify="start" style={{
                // height: '62vh', 
                alignContent: 'space-between', 
                overflowY: 'auto'
                }}>

                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  {replyMessage}
                </Col>
                <Col span={19}>
                  <Form.Item name='replyMessage' style={{ marginBottom: '0px' }}
                    rules={[{ max: 200, message: intl.formatMessage({ id: 'estimate.maxError' }, { a: intl.formatMessage({ id: 'docInfo.replyContent' }), b: '200' }) }]}>
                    <TextArea 
                      autoSize={{ minRows: 13, maxRows: 13 }} 
                      disabled={props.disabled}
                      maxLength={200}
                      showCount
                      onChange={handleEditReplyMessage}
                    />
                  </Form.Item>
                </Col>
                <Col span={5} style={{ textAlign: 'right', fontWeight: 'bold', padding: '3px 8px' }}>
                  {fileList}
                </Col>
                <Col span={19}>
                  <div className='reSize'>
                    <Form.Item name='attachments' noStyle>
                      <CommonFileUpload
                        attachments={!!props.formDto ? props.formDto.detailAttachments : []}
                        canUpload={!props.disabled}
                        persist={(uuid: string) => {
                          setDetailAttachments([...detailAttachments, { id: -1, uuid: uuid }]);
                        }}
                        remove={(uuid: string) => {
                          setDetailAttachments(detailAttachments.filter((attachment: Attachment) => attachment.uuid !== uuid))
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col>
            {!props.disabled && <Popconfirm
              title={replyConfirm}
              onConfirm={() => {
                form.submit()
              }}
              okText={confirm}
              cancelText={replyCancel}
              disabled={replyDisabled}
            >
              <Button style={{ marginTop: 40 }} disabled={replyDisabled} >{reply}</Button>
            </Popconfirm>}
          </Col>
        </Row>
      </Form>
    </Drawer >
  );
}
